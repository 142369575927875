import $ from 'jquery';
//import './components/balancetext';

/*if ($('body').hasClass('single') || $('body').hasClass('tag') || $('body').hasClass('tax-trainingtag') || $('body').hasClass('error404')) {
	if($(window).width() < 1230) {
		setTimeout(function() {
			$('#mm-3').removeClass('mm-hidden').addClass('mm-panel_opened'); // mm-panel_opened
			//$( ".mm-panel:has(.mm-navbar" ).addClass( "test" );

		}, 500)
		console.log($('.mm-panel.mm-panel_opened').children().length);
		if ( $('.mm-panel.mm-panel_opened').children().length > 0 ) {
		     // do something
		     console.log('koko');
		}
		//$('.mm-panel.mm-panel_opened').
	}

}*/
/*
setTimeout(function() {
	if($(window).width() < 1230) {
		if ( $('.mm-panel.mm-panel_opened').children().length < 2 ) {
		     // do something
		     $('.mm-panel.mm-panel_opened').next().removeClass('mm-hidden').addClass('mm-panel_opened');
		     console.log('main menu activated');
		     // mm-panel_opened
		}
	}
}, 500);
*/
// console.log('testing');

if ($('body').hasClass("single-training")) {
	//console.log('single-training');
	$('.nav-tabs a').click(function (e) {
		console.log('click');
	  if($(this).attr("href") != "#nav-individuals"){
	     $(".type-training").addClass('individuals-hidden');
	  }
	  else{
	     $(".type-training").removeClass('individuals-hidden');
	  }
	});
}

if ($(window).width() > 991) {
	if ($('body').hasClass("single-post") && $('.blog-series-sidebar')[0]) {
		var height = $('.entry-meta').outerHeight() + $('.entry-excerpt').outerHeight() + $('#post-title').outerHeight() + 41; //$('.header').css('padding-bottom');
		$('.blog-series-sidebar').css({'margin-top': -height, 'padding-left': '8.3333333333%'});
	}
}


/*
if ($('body').hasClass("single-encyclopedia")) {
	$('.search-area').on('mouseover', function() {
		$('#glossary-search').fadeIn();
	});
	$('.search-area').on('mouseout', function() {
		$('#glossary-search').fadeOut();
	});
}*/

// menu

//alert(document.domain);

$('#menu-desktop li a').filter(function() {
	return this.href === location.href;
	console.log(this.href + ' ' + location.href);
}).addClass('current-menu-item').attr('aria-current', 'page');

//bigScreen();
function bigScreen(){
    if($(window).width() > 1360 && $('body').hasClass('single-post')) {
		setTimeout(function(){
			$('.fixme').css('display', 'grid');
			var headerHeight = $('#header').outerHeight() + $('#post-categories').outerHeight(),
				fixmeTop = $('.fixme').offset().top - headerHeight, 
				fixmeLeft = $('.fixme').offset().left, // + 90, // - margin-left
				topPosition = 160,
				containerBottom = $('.single-container').offset().top + $('.single-container').outerHeight() - $('.fixme').outerHeight() - topPosition - $('.fixme').outerHeight();
			//console.log('container: ' + containerBottom);
			//console.log('single container: ' + containerBottom, 'fixmeTop: ' + fixmeTop, 'headerheight: ' + headerHeight, 'offset Top: ' + $('.fixme').offset().top);
			$(window).on('scroll', function() {
				var currentScroll = $(window).scrollTop(); 
				//console.log(currentScroll);
				if (currentScroll >= fixmeTop - topPosition && currentScroll < containerBottom) {
					$('.fixme').css({ 
						position: 'fixed',
						top: headerHeight + topPosition,
						left: fixmeLeft
					});
				}  else {
					$('.fixme').css({ 
						position: 'absolute',
						top: 'unset',
						left: 'unset'
					});
				}
			});
		}, 200);
    } else {
    	$('.fixme').fadeOut();
    }
}
$(document).load($(window).on("resize", bigScreen())).resize();